
// @ts-nocheck


export const localeCodes =  [
  "en",
  "nl",
  "de",
  "es",
  "fr"
]

export const localeLoaders = {
  "en": [{ key: "../config/language/en.js", load: () => import("../config/language/en.js" /* webpackChunkName: "locale__vercel_path0_config_language_en_js" */), cache: true }],
  "nl": [{ key: "../config/language/nl.js", load: () => import("../config/language/nl.js" /* webpackChunkName: "locale__vercel_path0_config_language_nl_js" */), cache: true }],
  "de": [{ key: "../config/language/de.js", load: () => import("../config/language/de.js" /* webpackChunkName: "locale__vercel_path0_config_language_de_js" */), cache: true }],
  "es": [{ key: "../config/language/es.js", load: () => import("../config/language/es.js" /* webpackChunkName: "locale__vercel_path0_config_language_es_js" */), cache: true }],
  "fr": [{ key: "../config/language/fr.js", load: () => import("../config/language/fr.js" /* webpackChunkName: "locale__vercel_path0_config_language_fr_js" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.js?hash=c231e734&config=1" /* webpackChunkName: "__i18n_config_js_c231e734" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.js",
  "locales": [
    {
      "code": "en",
      "files": [
        "config/language/en.js"
      ]
    },
    {
      "code": "nl",
      "files": [
        "config/language/nl.js"
      ]
    },
    {
      "code": "de",
      "files": [
        "config/language/de.js"
      ]
    },
    {
      "code": "es",
      "files": [
        "config/language/es.js"
      ]
    },
    {
      "code": "fr",
      "files": [
        "config/language/fr.js"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "config/language/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "contact": {
      "en": "/contact",
      "de": "/kontakt",
      "nl": "/contact",
      "fr": "/contact",
      "es": "/contacto"
    },
    "contact-success": {
      "en": "/contact-success",
      "de": "/contact-success",
      "nl": "/contact-success",
      "fr": "/contact-success",
      "es": "/contact-success"
    },
    "events/index": {
      "en": "/events",
      "de": "/veranstaltungen",
      "nl": "/evenementen",
      "fr": "/evenements",
      "es": "/eventos"
    },
    "events/[item]": {
      "en": "/events/[item]",
      "de": "/veranstaltungen/[item]",
      "nl": "/evenementen/[item]",
      "fr": "/evenements/[item]",
      "es": "/eventos/[item]"
    },
    "faq": {
      "en": "/faq",
      "de": "/haufig-gestellte-fragen",
      "nl": "/veelgestelde-vragen",
      "fr": "/questions-frequemment-posees",
      "es": "/preguntas-frecuentes"
    },
    "gallery": {
      "en": "/gallery",
      "de": "/galerie",
      "nl": "/galerij",
      "fr": "/galerie",
      "es": "/galeria"
    },
    "index": {
      "en": "/",
      "de": "/",
      "nl": "/",
      "fr": "/",
      "es": "/"
    },
    "menus": {
      "en": "/menus",
      "de": "/menus",
      "nl": "/menus",
      "fr": "/menus",
      "es": "/menus"
    },
    "news/index": {
      "en": "/news",
      "de": "/nachrichten",
      "nl": "/nieuws",
      "fr": "/actualites",
      "es": "/noticias"
    },
    "news/[item]": {
      "en": "/news/[item]",
      "de": "/nachrichten/[item]",
      "nl": "/nieuws/[item]",
      "fr": "/actualites/[item]",
      "es": "/noticias/[item]"
    },
    "packages/index": {
      "en": "/special-offers",
      "de": "/arrangements",
      "nl": "/arrangementen",
      "fr": "/arrangements",
      "es": "/arreglos"
    },
    "packages/[item]": {
      "en": "/special-offers/[item]",
      "de": "/arrangements/[item]",
      "nl": "/arrangementen/[item]",
      "fr": "/arrangements/[item]",
      "es": "/arreglos/[item]"
    },
    "reviews": {
      "en": "/reviews",
      "de": "/rezensionen",
      "nl": "/beoordelingen",
      "fr": "/revues",
      "es": "/resenas"
    },
    "sitemap": {
      "en": "/sitemap",
      "de": "/sitemap",
      "nl": "/sitemap",
      "fr": "/sitemap",
      "es": "/sitemap"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "files": [
      {
        "path": "config/language/en.js"
      }
    ]
  },
  {
    "code": "nl",
    "files": [
      {
        "path": "config/language/nl.js"
      }
    ]
  },
  {
    "code": "de",
    "files": [
      {
        "path": "config/language/de.js"
      }
    ]
  },
  {
    "code": "es",
    "files": [
      {
        "path": "config/language/es.js"
      }
    ]
  },
  {
    "code": "fr",
    "files": [
      {
        "path": "config/language/fr.js"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
